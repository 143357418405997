import globalSelectedVenueState from '@beatgig/helpers/global-selected-venue-state'
import { useRouting } from 'expo-next-react-navigation'

// type Routes  = {
//     [key: string]: () => RouteProperty
// }

export const AuthedRoutes = {
  bookings: () =>
    ({
      routeName: 'bookings',
      web: { path: `/dashboard/bookings` },
      // native: {
      //   screen: 'bookings',
      // },
    } as const),
  artists: () =>
    ({
      routeName: 'artists',
      web: { path: `/dashboard/artists` },
    } as const),
  venues: () =>
    ({
      routeName: 'venues',
      web: { path: `/dashboard/venues` },
    } as const),
  booking: (id: string | null) =>
    id === null
      ? ({ routeName: 'booking' } as const)
      : ({
          // routeName: 'bookings',
          web: {
            path: `/dashboard/bookings/[id]`,
            as: `/dashboard/bookings/${id}`,
          },
          params: { id },
          // native: {
          //   screen: 'booking',
          // },
          routeName: 'booking',
        } as const),
  dashboard: () =>
    ({
      routeName: 'dashboard',
    } as const),
  account: () =>
    ({
      routeName: 'account',
      web: { path: '/dashboard/account' },
    } as const),
  automatedBookings: () => ({
    routeName: 'automatedBookings',
    web: { path: '/dashboard/magic' },
  }),
  likes: () =>
    ({
      routeName: 'likes',
      web: {
        path: '/dashboard/likes',
      },
    } as const),
  calendar: ({ venueId }: { venueId?: string } = {}) => {
    if (venueId) {
      // TODO move this to a `useNavigateToCalendar` hook to avoid side effects of this in render!!
      globalSelectedVenueState.setGlobalState('selectedVenueId', venueId)
    }
    return {
      routeName: 'calendar',
      web: {
        path: '/dashboard/calendar',
        as: venueId ? `/dashboard/calendar?venue=${venueId}` : undefined,
      },
      params: venueId ? { venue: venueId } : undefined,
    } as const
  },
  venueApplicationsInbox: () =>
    ({
      routeName: 'inbox',
      web: {
        path: 'dashboard/inbox',
      },
    } as const),
  venueApplicationsOutbox: () =>
    ({
      routeName: 'outbox',
      web: {
        path: 'dashboard/outbox',
      },
    } as const),
  refer: () =>
    ({
      routeName: 'refer',
      web: {
        path: 'dashboard/refer',
      },
    } as const),
} as const
